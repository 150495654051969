export enum EmailActivityType {
    None,
    ChangePassword,
    ShareLoanDataConsent,
    Unsubscribe,
    /**
     * @deprecated Component may be removed.
     * @deprecatedSince 1.1.48
     */
    SmsUnsubscribe,
    ReaSignUp,
}
