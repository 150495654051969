// eslint-disable-next-line @typescript-eslint/no-inferrable-types
export const envPrefix: string = window.location.host.split('.')[0];

export const NAF_CONNECT_API_KEY = '2789A38C-964A-476E-9ADA-E01235740CAF';

export const NAF_CONNECT_API_BASE_URL = ((): string => {
    let apiEndpointUrl = 'https://{env}api.naf-connect.com/api';

    switch (envPrefix) {
        case 'www':
        case 'naf-connect':
            apiEndpointUrl = apiEndpointUrl.replace('{env}', '');
            break;
        case 'staging':
        case 'uat':
        case 'qa':
        case 'dev':
            apiEndpointUrl = apiEndpointUrl.replace('{env}', envPrefix);
            break;
        default:
            apiEndpointUrl = `https://${window.location.host.split(':')[0]}:7100/api`;
            break;
    }

    // TODO: REMOVE AFTER TESTING OPTIMIZELY IN QA
    if (window.location.host.includes('optimizely')) {
        apiEndpointUrl = 'https://qaapi.naf-connect.com/api';
    }

    return apiEndpointUrl;
})();

export const GA_MEASUREMENT_ID = ((): string => {
    switch (envPrefix) {
        case 'www':
        case 'naf-connect':
            return 'G-Y5V5TQYZS0';
        case 'staging':
            return 'G-7FG20VK2NG';
        case 'uat':
            return 'G-49N82MV7QV';
        case 'qa':
        case 'dev':
        case 'localhost':
        default:
            return 'G-2HTVE3PDES';
    }
})();

export const TEST_GA_MEASUREMENT_ID = ((): string => {
    switch (envPrefix) {
        case 'www':
        case 'naf-connect':
        case 'staging':
            return 'G-7FG20VK2NG';
        case 'uat':
            return 'G-49N82MV7QV';
        case 'qa':
            return 'G-KY8HGBEDVC';
        case 'dev':
        case 'localhost':
        default:
            return 'G-2HTVE3PDES';
    }
})();

export const termsUrl = 'https://www.newamericanfunding.com/legal/terms-of-use-website/';
export const privacyUrl = 'https://www.newamericanfunding.com/legal/privacy-policy/';

export const getAppVersion = (): string =>
    document.querySelector('meta[name="version-info"]')?.getAttribute('content') ?? '';
