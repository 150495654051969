import { Action, UIActionEnum } from 'app/store/api/integrations/models/integrations.model';
import { useAppDispatch } from 'app/store/hooks';
import { setLeadDetails } from 'app/store/slices/refer-client.slice';
import {
    ReactNode,
    createContext,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useLocalStorage } from 'usehooks-ts';
import { useLandingPage } from '../landing-page/landing-page.context';
import { isAuthenticated } from 'app/shared/data';

interface UIActionsProviderProps {
    children: ReactNode;
}

interface UIActionsContext {
    currentAction: Action;
    loadActions: (actions: Action[]) => void;
    dequeueAction: () => void;
    setReaSource: (source: string) => void;
    redirectUrl: string;
    setRedirectUrl: (url: string) => void;
}

const UIActionsContext = createContext<UIActionsContext>({
    currentAction: {} as Action,
    loadActions: () => {},
    dequeueAction: () => {},
    setReaSource: () => {},
    redirectUrl: '',
    setRedirectUrl: () => {},
});

export const UIActionsProvider = ({ children }: UIActionsProviderProps): JSX.Element => {
    const [actions, setActions] = useLocalStorage<Action[]>('@nc_actions', []);
    const [redirectUrl, setRedirectUrl] = useLocalStorage('@nc_redirect_url', '');
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { setLoginDetails, setPrefilledRegistrationDetails } = useLandingPage();
    const [source, setSource] = useState('');

    const setReaSource = useCallback(
        (source: string): void => {
            setSource(source);
        },
        [setSource]
    );

    useEffect(() => {
        const currentAction = actions[0];
        if (currentAction) {
            switch (currentAction.action) {
                case UIActionEnum.Signup: {
                    setPrefilledRegistrationDetails({
                        agentFirstName: currentAction.agentFirstName || '',
                        agentLastName: currentAction.agentLastName || '',
                        agentEmailAddress: currentAction.agentEmailAddress || '',
                        agentStateCode: currentAction.agentStateCode || '',
                        agentPhoneNumber: currentAction.agentPhoneNumber || '',
                        nafHomesId: currentAction.nafHomesId ? `${currentAction.nafHomesId}` : '',
                        reaSource: currentAction.source || source || '',
                        loanOfficerId: currentAction.loanOfficerId ?? '',
                    });
                    break;
                }
                case UIActionEnum.Login: {
                    setLoginDetails({
                        agentEmailAddress: currentAction.agentEmailAddress || '',
                    });
                    break;
                }
                case UIActionEnum.ClientReferral: {
                    const {
                        leadFirstName,
                        leadLastName,
                        leadEmailAddress,
                        leadAddress1,
                        leadAddress2,
                        leadZipCode,
                        leadStateCode,
                        leadPhoneNumber,
                    } = currentAction;

                    dispatch(
                        setLeadDetails({
                            leadFirstName,
                            leadLastName,
                            leadEmailAddress,
                            leadAddress1,
                            leadAddress2,
                            leadZipCode,
                            leadStateCode,
                            leadPhoneNumber,
                        })
                    );
                    break;
                }
                case UIActionEnum.ClientStatus: {
                    break;
                }
                default:
                    break;
            }

            const navigateToActionUrl = (actionUrl: string): void => {
                const url = new URL(actionUrl);
                const pathSegments = url.pathname
                    .split('/')
                    .filter((segment) => segment.length > 0);
                const relevantPath = pathSegments.join('/');

                // Condition prevents actions navigating when returning from LinkedIn SSO login
                if (location.pathname !== '/') {
                    navigate(relevantPath);
                }
            };

            if (currentAction.actionUrl) {
                if (currentAction.action === UIActionEnum.Login) {
                    if (isAuthenticated()) {
                        dequeueAction();
                    } else {
                        navigateToActionUrl(currentAction.actionUrl);
                    }
                } else {
                    navigateToActionUrl(currentAction.actionUrl);
                }
            }
        }
    }, [actions]);

    const loadActions = useCallback(
        (actions: Action[]): void => {
            setActions(actions);
        },
        [setActions]
    );

    const dequeueAction = useCallback((): void => {
        if (actions.length) {
            setActions((prevActions) => {
                const newActions = prevActions.slice(1);
                return newActions;
            });
        }
    }, [actions, setActions]);

    const value = useMemo(
        () => ({
            currentAction: actions[0],
            loadActions,
            dequeueAction,
            setReaSource,
            redirectUrl,
            setRedirectUrl,
        }),
        [actions, loadActions, dequeueAction, setReaSource, redirectUrl, setRedirectUrl]
    );

    return <UIActionsContext.Provider value={value}>{children}</UIActionsContext.Provider>;
};

export const useActions = (): UIActionsContext => useContext(UIActionsContext);
