import { httpMethod } from 'app/services/_httpClient';
import { axiosBaseQuery } from '../axiosBaseQuery';
import { NAF_CONNECT_API_BASE_URL } from 'app/shared/helpers/settings.helper';
import { createApi } from '@reduxjs/toolkit/query/react';
import {
    EmailUnsubscribeRequest,
    EmailUnsubscribeResponse,
    ValidateTokenRequest,
    ValidateTokenResponse,
} from './models/email-activity.model';

export const emailActivityApi = createApi({
    reducerPath: 'emailActivity-api',
    baseQuery: axiosBaseQuery({
        baseUrl: `${NAF_CONNECT_API_BASE_URL}/emailActivity/`,
    }),
    endpoints: (builder) => ({
        processEmailActivity: builder.mutation<ValidateTokenResponse, ValidateTokenRequest>({
            query: (data) => ({
                url: '',
                method: httpMethod.Post,
                data,
            }),
            transformResponse: (response: { data: ValidateTokenResponse }) => response.data,
        }),
        /**
         * @deprecated may be removed.
         * @deprecatedSince 1.1.48
         */
        validateToken: builder.mutation<ValidateTokenResponse, ValidateTokenRequest>({
            query: (data) => ({
                url: 'validateToken',
                method: httpMethod.Post,
                data,
            }),
            transformResponse: (response: { data: ValidateTokenResponse }) => response.data,
        }),
        /**
         * @deprecated may be removed.
         * @deprecatedSince 1.1.48
         */
        unsubscribe: builder.mutation<EmailUnsubscribeResponse, EmailUnsubscribeRequest>({
            query: (data) => ({
                url: 'unsubscribe',
                method: httpMethod.Post,
                data,
            }),
            transformResponse: (response: { data: EmailUnsubscribeResponse }) => response.data,
        }),
    }),
});

export const { useProcessEmailActivityMutation, useValidateTokenMutation, useUnsubscribeMutation } =
    emailActivityApi;
